import { Transport } from 'tone'
import {
  isTransportStarted, setTransportBPM, setTransportLooping, setTransportLoopPoints,
  startTransport, stopTransport,
}                    from 'framework/helpers/tone-helpers'

export default {
  toggleTransport({ dispatch }, { start } = {}) {
    const action = isTransportStarted() ? 'stopPlayback' : 'startPlayback'
    dispatch(action, start)
  },

  stopPlayback({ commit, dispatch }) {
    stopTransport()
    commit('SET_TRANSPORT_STATE', 'stopped')
    dispatch('resetTransport')
  },

  startPlayback({ commit }, { start } = {}) {
    commit('SET_TRANSPORT_STATE', 'started')
    startTransport(start)
  },

  pauseTransport({ commit }) {
    Transport.pause()
    commit('SET_TRANSPORT_STATE', 'paused')
  },

  resumeTransport({ commit }) {
    Transport.start()
    commit('SET_TRANSPORT_STATE', 'started')
  },

  resetTransport({ getters, dispatch }) {
    if (getters.getIsTransportStarted) {
      dispatch('toggleTransport')
    } else {
      Transport.cancel()
    }
  },

  setBPM({ commit }, value) {
    commit('SET_BPM', value)
    setTransportBPM(value)
  },

  setLooping({ commit }, value) {
    commit('SET_MASTER_LOOP', value)
    setTransportLooping(value)
  },

  setLoopPoints({ commit }, loopPoints) {
    commit('SET_LOOP_POINTS', loopPoints)
    setTransportLoopPoints(loopPoints)
  },
}
