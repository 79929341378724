export default {
  SET_LEAD_INSTRUMENT(state, id) {
    state.main_player = id
  },

  SET_PLAYER_INSTRUMENT(state, { id, value }) {
    state.players = {
      ...state.players,
      [id]: {
        ...state.players[id],
        instrument: value,
      },
    }
  },

  SET_PLAYER_MUTE(state, { id, value }) {
    state.players = {
      ...state.players,
      [id]: {
        ...state.players[id],
        mute: value,
      },
    }
  },

  SET_INSTRUMENTS_READY(state) {
    state.instruments_ready = true
  },

  SET_PLAYERS_IN_SCORE(state, num) {
    state.players_in_score = num
  },
}
