import audioManager       from 'framework/models/AudioManager'
import { partsHandler }   from 'framework/models/PartsHandler'
import { DEFAULT_PLAYER } from 'framework/resources/framework-constants'
import { localStore }     from 'framework/helpers/LocalStorageHandler'

export default {
  async setLeadPlayer({ commit }, { id }) {
    commit('SET_LEAD_INSTRUMENT', id)
  },

  setPlayerMute({ commit }, { id, value }) {
    partsHandler.getPart(id).mute = value
    commit('SET_PLAYER_MUTE', {
      id,
      value
    })
  },

  async setPlayerInstrument({ commit }, { id, value }) {
    if(id === DEFAULT_PLAYER) {
      await localStore.set('instrument', value)
    }
    await audioManager.add(value)
    commit('SET_PLAYER_INSTRUMENT', {
      id,
      value
    })
  }
}