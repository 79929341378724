export default {
  SET_PIANO_KEY(state, { key, octave, val }) {
    state.piano_keys.splice(octave, 1, { ...state.piano_keys[octave], [key]: val })
  },

  SET_KEY_COLOR(state, value) {
    state.key_color = value
  },

  SET_IS_MINI_PIANO(state, value) {
    state.is_mini_piano = value
  },

  SET_PIANO_KEYS(state, pianoKeys) {
    this.piano_keys = pianoKeys
  },
}
