import { APP_PLAYER_NAMES, DRUMS_PLAYER } from 'framework/resources/framework-constants'

const instruments = [
  'piano',
  'bass-electric',
  'cello',
  'flute',
  'guitar-acoustic',
  'guitar-nylon',
  'harmonium',
  'organ',
  'guitar-electric',
  'violin',
  'saxophone',
  'tuba',
  'xylophone',
  'clarinet',
]

const playerConfigs = instruments.map((instrument) => {
  return {
    instrument,
    mute: false,
  }
})

const players = {}
APP_PLAYER_NAMES.forEach((player, index) => {
  players[player] = playerConfigs[index]
})

players[DRUMS_PLAYER] = {
  instrument: 'drums',
  mute:       false,
}

export default {
  main_player:       APP_PLAYER_NAMES[0],
  players,
  instruments_ready: false,
  players_in_score:  0,
}
