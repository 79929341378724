export default {
  SET_MASTER_LOOP(state, value) {
    state.master_loop = value
  },

  SET_TRANSPORT_STATE(state, val) {
    state.transport_state = val
  },

  SET_BPM(state, value) {
    state.bpm = value
  },

  SET_LOOP_POINTS(state, loopPoints) {
    state.loop_points = loopPoints
  },
}
