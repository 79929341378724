import state     from 'framework/store/piano/piano.state'
import getters   from 'framework/store/piano/piano.getters'
import mutations from 'framework/store/piano/piano.mutations'
import actions   from 'framework/store/piano/piano.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
