import state     from './transport.state'
import getters   from './transport.getters'
import mutations from './transport.mutations'
import actions   from './transport.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
