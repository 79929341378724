import { context, Time }         from 'tone'
import { getConstants }             from 'framework/helpers/note-art-helpers'
import { toFlatPitchClass, noteToObject } from 'framework/utilities/Utilities'

export default {
  autoPianoKeysPress({ dispatch }, { keys, dur }) {
    dispatch('setPianoKeys', { keys, val: true })
    context.setTimeout(() => dispatch('setPianoKeys', { keys, val: false })
      , Time(dur).toSeconds() - 0.1)
  },

  setPianoKeys({ commit, getters }, { keys = [], val, keyColor = 'success' }) {
    commit('SET_KEY_COLOR', keyColor)
    for (const key of keys) {
      const { pitchClass, octave } = noteToObject(key)
      commit('SET_PIANO_KEY', {
        key:    toFlatPitchClass(pitchClass),
        octave: getters.getIsMiniPiano ? 4 : octave,
        val,
      })
    }
  },

  setIsMiniPiano({ commit }, value) {
    commit('SET_IS_MINI_PIANO', value)
  },

  clearPiano({ dispatch }) {
    for (let i = 0; i < 8; ++i) {
      const keys = getConstants().flatClassNotes.map(pc => `${pc}${i}`)
      dispatch('setPianoKeys', { keys, val: false })
    }
  },
}
