import state     from './players.state'
import getters   from './players.getters'
import mutations from './players.mutations'
import actions   from './players.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
