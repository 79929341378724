import { getConstants } from 'framework/helpers/note-art-helpers'

// eslint-disable-next-line camelcase
const piano_keys = []
for(let i = 0; i < 8; ++i) {
  piano_keys.push({})
  getConstants().flatClassNotes.forEach(pc => {
    piano_keys[i][pc] = false
  })
}

export default {
  piano_keys,
  is_mini_piano:      false,
  key_color:        'green',
}
