import { createScale }                         from 'framework/helpers/note-art-helpers'
import audioManager                            from 'framework/models/AudioManager'
import { DEFAULT_OCTAVE, DEFAULT_PITCH_CLASS } from 'framework/resources/framework-constants'
import { firstToUpper }                        from 'framework/utilities/Utilities'

export default {
  setPitchClass({ getters, commit }, { key = DEFAULT_PITCH_CLASS, pitchClass }) {
    pitchClass = firstToUpper(pitchClass)
    commit('SET_PITCH_CLASS', { key, pitchClass })

    const oldScale = getters.getScale
    if(oldScale) {
      const scale = createScale(pitchClass, oldScale.pattern, oldScale.info)
      commit('SET_SCALE', scale)
    }
  },

  setScale({ commit }, scale) {
    commit('SET_SCALE', scale)
  },

  setOctave({ rootGetters, getters, commit }, { key = DEFAULT_OCTAVE, octave }) {
    commit('SET_OCTAVE', { key, octave })
    const baseNote = `${ getters.getDefaultPitch }${ octave }`
    audioManager.loadNotes(rootGetters['players/getMainPlayerInstrument'], baseNote)
  }
}
