import { DEFAULT_OCTAVE, DEFAULT_PITCH_CLASS } from 'framework/resources/framework-constants'

export default {
  getDefaultPitch:  state => state.pitch_classes[DEFAULT_PITCH_CLASS],
  getDefaultOctave: state => state.octaves[DEFAULT_OCTAVE],
  getPitchClass:    state => (key = DEFAULT_PITCH_CLASS) => state.pitch_classes[key],
  getOctave:        state => (key) => state.octaves[key],
  getPitch:         state => (key = DEFAULT_PITCH_CLASS) => `${ state.pitch_classes[key] }${ state.octaves[key] }`,
  getDefaultNote:   state => `${ state.pitch_classes[DEFAULT_PITCH_CLASS] }${ state.octaves[DEFAULT_OCTAVE] }`,
  getScale:         state => state.scale
}
