import state   from 'framework/store/input/input.state'
import getters from 'framework/store/input/input.getters'
import mutations from 'framework/store/input/input.mutations'
import actions   from 'framework/store/input/input.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
