import state     from 'framework/store/context/context.state'
import getters   from 'framework/store/context/context.getters'
import mutations from 'framework/store/context/context.mutations'
import actions   from 'framework/store/context/context.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
