import store     from 'src/store'
import input     from 'framework/store/input'
import piano     from 'framework/store/piano'
import metronome from 'framework/store/metronome'
import transport from 'framework/store/transport'
import context   from 'framework/store/context'
import player    from 'framework/store/players'

export function setupVuexModules() {
  store.registerModule('input', input)
  store.registerModule('piano-state', piano)
  store.registerModule('metronome', metronome)
  store.registerModule('transport', transport)
  store.registerModule('context', context)
  store.registerModule('players', player)
}
