export default {
  SET_PITCH_CLASS(state, { key, pitchClass }) {
    state.pitch_classes = { ...state.pitch_classes, [key]: pitchClass }
  },

  SET_SCALE(state, scale) {
    state.scale = scale
  },

  SET_OCTAVE(state, { key, octave }) {
    state.octaves = { ...state.octaves, [key]: octave }
  },
}
