import state     from './metronome.state'
import getters   from './metronome.getters'
import mutations from './metronome.mutations'
import actions   from './metronome.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
