import { Draw, Loop, Part }          from 'tone'
import { DRUMS_PLAYER }              from 'framework/resources/framework-constants'
import audioManager                  from 'framework/models/AudioManager'
import { setTransportTimeSignature } from 'framework/helpers/tone-helpers'
import { partsHandler }              from 'framework/models/PartsHandler'

export default {
  setMetronome({ commit }, config) {
    commit('SET_METRONOME', config)
  },

  setBeat({ commit }, { value }) {
    commit('SET_BEAT', value)
  },

  async scheduleMetronome({ commit, getters }, config) {
    const { firstDownBeat, downBeat, upBeat, numerator, denominator, isTriplets } = config
    setTransportTimeSignature([numerator, denominator])
    const events     = []
    const beat       = isTriplets ? denominator * 2 : denominator
    const beatType   = isTriplets ? 't' : 'n'
    const beatTime   = `${ beat }${ beatType }`
    const upBeatTime = `${ denominator * 2 }n`

    if( !isTriplets) {
      events.push(...getMetronomeTimeEvents({
        firstDownBeat,
        downBeat,
        numerator,
        beatTime,
        upBeat,
        upBeatTime
      }))
    } else {
      events.push(...getMetronomeTripletTimeEvents({
        firstDownBeat,
        downBeat,
        numerator,
        beatTime
      }))
    }

    const beatKeeper = new Loop((time) => {
      Draw.schedule(() => {
        const prevBeat = getters.getBeat
        const newBeat  = prevBeat + 1 > numerator ? 1 : prevBeat + 1
        commit('SET_BEAT', newBeat)
      }, time)
    }, `${ denominator }n`)
    beatKeeper.start(0)
    partsHandler.addPart('beat_keeper', beatKeeper)

    const callback = (time, { sounds }) => {
      audioManager[DRUMS_PLAYER].play({ notes: sounds, time })
    }
    const part     = new Part(callback, events)
    part.loop      = true

    partsHandler.addPart(DRUMS_PLAYER, part)
    part.start(0)
  }
}

/**
 *
 * @param events
 * @param numerator
 * @param firstDownBeat
 * @param downBeat
 * @param upBeat
 * @param upBeatTime
 * @param beatTime
 */
function getMetronomeTimeEvents({ numerator, firstDownBeat, downBeat, upBeat, upBeatTime, beatTime }) {
  const events = []
  const time   = { [beatTime]: 0 }
  for(let i = 0; i < numerator; ++i) {
    const sound = i ? downBeat : firstDownBeat
    events.push({
      time:   { ...time },
      i,
      sounds: [sound]
    })
    if(upBeat) {
      events.push({
        time:   {
          ...time,
          [upBeatTime]: 1
        },
        i,
        sounds: [upBeat]
      })
    }
    ++time[beatTime]
  }
  return events
}

function getMetronomeTripletTimeEvents({ numerator, firstDownBeat, downBeat, beatTime }) {
  const events = []
  const time   = { [beatTime]: 0 }
  for(let i = 0; i < numerator * 3; ++i) {
    const sound = i > 2 ? downBeat : firstDownBeat
    events.push({
      time:   { ...time },
      i,
      sounds: [sound]
    })
    ++time[beatTime]
  }
  return events
}